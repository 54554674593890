<template>
  <div>
    <StartDate
      :task="task"
      :noArrow="noArrow"
      :fitContent="fitContent"
      :disabled="!hasEditPermission"
      @change:startDate="changeStartDate"
      @delete:startDate="deleteStartDate"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StartDate from "@/flow/views/components/commons/task/startDate";

export default {
  components: { StartDate },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", ["updateStartDate"]),
    changeStartDate(startDateTimeMillis) {
      const { id: taskId } = this.task;
      this.updateStartDate({ taskId, startDateTimeMillis });
    },
    deleteStartDate() {
      const { id: taskId } = this.task;
      this.updateStartDate({ taskId, startDateTimeMillis: 0 });
    }
  }
};
</script>
