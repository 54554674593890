<template>
  <v-btn block class="cr-activator" v-on="$listeners">
    <div class="d-flex flex-grow-1 align-center justify-space-between">
      <span :class="{ 'cr-not-exists': valueNotExists }">
        {{ startDate }}
      </span>

      <ClearBtn
        v-if="!valueNotExists && !disabled"
        @click.stop="$emit('clear')"
      />
    </div>
  </v-btn>
</template>

<style lang="scss" scoped>
.v-btn.cr-activator::v-deep {
  height: auto !important;
  min-height: 40px !important;
  padding: 0 12px !important;
  box-shadow: unset !important;
  background-color: #fff;
  border-radius: 8px;
  border: thin solid rgba(0, 0, 0, 0.12);
  > .v-btn__content {
    justify-content: flex-start !important;
    flex-wrap: wrap;
    width: fit-content;
    padding: 4px 0px 6px;
    .cr-not-exists {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &:focus::before {
    opacity: 0 !important;
  }
}
</style>

<script>
import { getDateWithoutTime } from "@/commons/utils/moment";
import ClearBtn from "./ClearBtn.vue";

export default {
  components: { ClearBtn },
  emits: ["delete:startDate"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueNotExists() {
      return !this.task?.startDateTimeMillis;
    },
    startDate() {
      if (this.valueNotExists) return "시작일 설정";
      return getDateWithoutTime(this.task.startDateTimeMillis);
    }
  }
};
</script>
