<template>
  <v-date-picker
    no-title
    color="primary"
    :day-format="dayFormat"
    :full-width="fitContent"
    v-model="startDate"
    @input="stInput"
  />
</template>

<style lang="scss" scoped>
.v-picker.v-picker--date::v-deep {
  .v-date-picker-header {
    padding-top: 16px;
  }
  .v-date-picker-table {
    height: 232px;
  }
}
</style>

<script>
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  emits: ["change"],
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startDate: {
      get() {
        const { startDateTimeMillis: start } = this.task || {};
        return start ? getDateWithoutTime(start, "YYYY-MM-DD") : "";
      },
      set() {}
    }
  },
  methods: {
    dayFormat(date) {
      return new Date(date).getDate();
    },
    // 날짜 눌렀을때 타는 함수
    stInput(startDate) {
      const startDateTimeMillis = new Date(startDate).getTime();
      this.$emit("change", startDateTimeMillis);
    }
  }
};
</script>
